import { DependencyContainer } from "tsyringe";
import { AuthDataMapper } from "@/data/persistences/mappers/AuthDataMapper";
import { BulkDataMapper } from "@/data/persistences/mappers/BulkDataMapper";
import { AccountDataMapper } from "@/data/persistences/mappers/AccountMapper";
import { LocationDataMapper } from "@/data/persistences/mappers/LocationDataMapper";
import { UserDataMapper } from "@/data/persistences/mappers/UserDataMapper";
import { NotificationDataMapper } from "@/data/persistences/mappers/NotificationMapper";
import { VendorDataMapper } from "@/data/persistences/mappers/VendorDataMapper";
import { EmbargoDataMapper } from "@/data/persistences/mappers/EmbargoMapper";
import { LegDataMapper } from "@/data/persistences/mappers/LegDataMapper";
import { RouteDataMapper } from "@/data/persistences/mappers/RouteMapper";
import { TransportDataMapper } from "@/data/persistences/mappers/TransportDataMapper";
import { BalanceLimitMapper } from "@/data/persistences/mappers/BalanceLimitMapper";
import { CommodityMapper } from "@/data/persistences/mappers/CommodityMapper";
import { LocationSearchMapper } from "@/data/persistences/mappers/LocationSearchMapper";
import { ConfigPriceDataMapper } from "@/data/persistences/mappers/ConfigPriceMapper";
import { ConfigPriceWoodpackingDataMapper } from "@/data/persistences/mappers/ConfigWoodpackingMapper";
import { HeavyWeightSurchargeMapper } from "@/data/persistences/mappers/HeavyWeightSurchargeMapper";
import { InsuranceMapper } from "@/data/persistences/mappers/InsuranceMapper";
import { CommoditySurchargeMapper } from "@/data/persistences/mappers/CommoditySurchargeMapper";
import { BaseRatesMapper } from "@/data/persistences/mappers/BaseRatesMapper";
import { ExchangeRateDataMapper } from "@/data/persistences/mappers/ExchangeRateMapper";
import { GoodsTaxMapper } from "@/data/persistences/mappers/GoodsTaxMapper";
import { ClientMapper } from "@/data/persistences/mappers/ClientMapper";
import { ClientRegistrationMapper } from "@/data/persistences/mappers/ClientRegistrationMapper";
import { AnnouncementMapper } from "@/data/persistences/mappers/AnnouncementMapper";
import { ClientManagementMapper } from "@/data/persistences/mappers/ClientManagementMapper";
import { ManualSTTDataMapper } from "@/data/persistences/mappers/ManualSTTMapper";
import { TariffDataMapper } from "@/data/persistences/mappers/TariffMapper";
import { BookingMapper } from "@/data/persistences/mappers/BookingMapper";
import { ShipmentBookingMapper } from "@/data/persistences/mappers/ShipmentBookingMapper";
import { ListSttMapper } from "@/data/persistences/mappers/ListSttMapper";
import { CnManifestMapper } from "@/data/persistences/mappers/CnManifestMapper";
import { PickupManifestMapper } from "@/data/persistences/mappers/PickupManifestMapper";
import { VehicleDataMapper } from "@/data/persistences/mappers/VehicleMapper";
import { StiSCMapper } from "@/data/persistences/mappers/StiSCMapper";
import { STIMapper } from "@/data/persistences/mappers/STIMapper";
import { BaggingMapper } from "@/data/persistences/mappers/BaggingMapper";
import { StiDestMapper } from "@/data/persistences/mappers/StiDestMapper";
import { StiDestScMapper } from "@/data/persistences/mappers/StiDestScMapper";
import { HandoverMapper } from "@/data/persistences/mappers/HandoverMapper";
import { ShortlandMapper } from "@/data/persistences/mappers/ShortlandMapper";
import { PodDexMapper } from "@/data/persistences/mappers/PodDexMapper";
import { DeliveryManifestMapper } from "@/data/persistences/mappers/DeliveryManifestMapper";
import { PosMapper } from "@/data/persistences/mappers/PosMapper";
import { ConsolidatorMapper } from "@/data/persistences/mappers/ConsolidatorMapper";
import { PartnerSubconsoleMapper } from "@/data/persistences/mappers/PartnerSubconsoleMapper";
import { PartnerMapper } from "@/data/persistences/mappers/PartnerMapper";
import { CustomProcessMapper } from "@/data/persistences/mappers/CustomProcessMapper";
import { PosClientMapper } from "@/data/persistences/mappers/PosClientMapper";
import { BulkBookingMapper } from "@/data/persistences/mappers/BulkBookingMapper";
import { AdjustmentPenaltyMapper } from "@/data/persistences/mappers/AdjustmentPenaltyMapper";
import { RefundConfigurationMapper } from "@/data/persistences/mappers/RefundConfigurationMapper";
import { AuthAlgoMapper } from "@/data/persistences/mappers/AuthAlgoMapper";
import { PosFavoriteMapper } from "@/data/persistences/mappers/PosFavoriteMapper";
import { BalanceMapper } from "@/data/persistences/mappers/BalanceMapper";
import { CodIncomeMapper } from "@/data/persistences/mappers/CodIncomeMapper";
import { TrackingMapper } from "@/data/persistences/mappers/TrackingMapper";
import { CargoMapper } from "@/data/persistences/mappers/CargoMapper";
import { BeneficiaryMapper } from "@/data/persistences/mappers/BeneficiaryMapper";
import { BulkBookingCommissionMapper } from "@/data/persistences/mappers/BulkBookingCommissionMapper";
import { BookingCommissionMapper } from "@/data/persistences/mappers/BookingCommissionMapper";
import { ManualDeductAddSaldoMapper } from "@/data/persistences/mappers/ManualDeductAddSaldoMapper";
import { TopUpVerificationMapper } from "@/data/persistences/mappers/TopUpVerificationMapper";
import { TopupApprovalMapper } from "@/data/persistences/mappers/TopupApprovalMapper";
import { BulkDownloadMapper } from "@/data/persistences/mappers/BulkDownloadMapper";
import { DeliveryTieringPolicyMapper } from "@/data/persistences/mappers/DeliveryTieringPolicyMapper";
import { UpdateDeliveryMapper } from "@/data/persistences/mappers/UpdateDeliveryMapper";
import { MessagingServiceConfigurationMapper } from "@/data/persistences/mappers/MessagingServiceConfigurationMapper";
import { ReportMapper } from "@/data/persistences/mappers/ReportMapper";
import { NGenMapper } from "@/data/persistences/mappers/NGenMapper";
import { IntegrationMonitoringMapper } from "@/data/persistences/mappers/IntegrationMonitoringMapper";
import { MiddlewareMapper } from "@/data/persistences/mappers/MiddlewareMapper";
import { InstantBookingMapper } from "@/data/persistences/mappers/InstantBookingMapper";
import { UniversalPrintMapper } from "@/data/persistences/mappers/UniversalPrintMapper";
import { DashboardMapper } from "@/data/persistences/mappers/DashboardMapper";
import { EmailMapper } from "@/data/persistences/mappers/EmailMapper";
import { BalanceTransactionMapper } from "@/data/persistences/mappers/BalanceTransactionMapper";
import { LastBalanceMapper } from "@/data/persistences/mappers/LastBalanceMapper";
import { ForwardBookingCommissionMapper } from "@/data/persistences/mappers/ForwardBookingCommissionMapper";
import { PaymentMapper } from "@/data/persistences/mappers/PaymentMapper";
import { IncomingOutgoingMapper } from "@/data/persistences/mappers/IncomingOutgoingMapper";
import { ProgressiveCommissionConfigurationMapper } from "@/data/persistences/mappers/ProgressiveCommissionConfigurationMapper";
import { STTPaidUnpaidMapper } from "@/data/persistences/mappers/STTPaidUnpaid";
import { UniversalMapper } from "@/data/persistences/mappers/UniversalMapper";
import { ProgressiveCommissionDashboardMapper } from "@/data/persistences/mappers/ProgressiveCommissionDashboardMapper";
import { BaggingGroupingMapper } from "@/data/persistences/mappers/BaggingGroupingMapper";
import { ClaimDataMapper } from "@/data/persistences/mappers/ClaimDataMapper";
import { TrackDeliveryMapper } from "@/data/persistences/mappers/TrackDeliveryMapper";
import { SaldoPoinMapper } from "@/data/persistences/mappers/SaldoPoinMapper";
import { CargoConfigurationMapper } from "@/data/persistences/mappers/CargoConfigurationMapper";
import { ServiceDelayMapper } from "@/data/persistences/mappers/ServiceDelayMapper";
import { ConfigPriceCodMapper } from "@/data/persistences/mappers/ConfigPriceCodMapper";
import { CourierManagementMapper } from "@/data/persistences/mappers/CourierManagementMapper";
import { CodConfigurationMapper } from "@/data/persistences/mappers/CodConfigurationMapper";
import { BalanceMinusPenaltyMapper } from "@/data/persistences/mappers/BalanceMinusPenaltyMapper";
import { HoldBalanceHistoryMapper } from "@/data/persistences/mappers/HoldBalanceHistoryMapper";
import { ConfigFakeDexIndicationMapper } from "@/data/persistences/mappers/ConfigFakeDexIndicationMapper";
import { DexAssessmentMapper } from "@/data/persistences/mappers/DexAsessmentMapper";
import { UrgentDeliveryMapper } from "@/data/persistences/mappers/UrgentDeliveryMapper";
import { UrgentDeliveryManagementMapper } from "@/data/persistences/mappers/UrgentDeliveryManagementMapper";
import { InternationalDocumentMapper } from "@/data/persistences/mappers/InternationalDocumentMapper";
import { CustomerMapper } from "@/data/persistences/mappers/CustomerMapper";
import { ListPickupMapper } from "@/data/persistences/mappers/ListPickupMapper";
import {CollectibleCardMapper} from "@/data/persistences/mappers/CollectibleCardMapper";

export class MapperModule {
  public static init(container: DependencyContainer) {
    container.register<AuthDataMapper>(AuthDataMapper, {
      useClass: AuthDataMapper
    });
    container.register<BulkDataMapper>(BulkDataMapper, {
      useClass: BulkDataMapper
    });
    container.register<AccountDataMapper>(AccountDataMapper, {
      useClass: AccountDataMapper
    });
    container.register<LocationDataMapper>(LocationDataMapper, {
      useClass: LocationDataMapper
    });
    container.register<NotificationDataMapper>(NotificationDataMapper, {
      useClass: NotificationDataMapper
    });
    container.register<UserDataMapper>(UserDataMapper, {
      useClass: UserDataMapper
    });
    container.register<VendorDataMapper>(VendorDataMapper, {
      useClass: VendorDataMapper
    });
    container.register<CommodityMapper>(CommodityMapper, {
      useClass: CommodityMapper
    });
    container.register<EmbargoDataMapper>(EmbargoDataMapper, {
      useClass: EmbargoDataMapper
    });
    container.register<LocationSearchMapper>(LocationSearchMapper, {
      useClass: LocationSearchMapper
    });
    container.register<LegDataMapper>(LegDataMapper, {
      useClass: LegDataMapper
    });
    container.register<RouteDataMapper>(RouteDataMapper, {
      useClass: RouteDataMapper
    });
    container.register<TransportDataMapper>(TransportDataMapper, {
      useClass: TransportDataMapper
    });
    container.register<BalanceLimitMapper>(BalanceLimitMapper, {
      useClass: BalanceLimitMapper
    });
    container.register<ConfigPriceDataMapper>(ConfigPriceDataMapper, {
      useClass: ConfigPriceDataMapper
    });
    container.register<ConfigPriceWoodpackingDataMapper>(
      ConfigPriceWoodpackingDataMapper,
      {
        useClass: ConfigPriceWoodpackingDataMapper
      }
    );
    container.register<HeavyWeightSurchargeMapper>(HeavyWeightSurchargeMapper, {
      useClass: HeavyWeightSurchargeMapper
    });
    container.register<InsuranceMapper>(InsuranceMapper, {
      useClass: InsuranceMapper
    });
    container.register<CommoditySurchargeMapper>(CommoditySurchargeMapper, {
      useClass: CommoditySurchargeMapper
    });
    container.register<BaseRatesMapper>(BaseRatesMapper, {
      useClass: BaseRatesMapper
    });
    container.register<ExchangeRateDataMapper>(ExchangeRateDataMapper, {
      useClass: ExchangeRateDataMapper
    });
    container.register<GoodsTaxMapper>(GoodsTaxMapper, {
      useClass: GoodsTaxMapper
    });
    container.register<ClientMapper>(ClientMapper, {
      useClass: ClientMapper
    });
    container.register<ClientRegistrationMapper>(ClientRegistrationMapper, {
      useClass: ClientRegistrationMapper
    });
    container.register<AnnouncementMapper>(AnnouncementMapper, {
      useClass: AnnouncementMapper
    });
    container.register<ClientManagementMapper>(ClientManagementMapper, {
      useClass: ClientManagementMapper
    });
    container.register<ManualSTTDataMapper>(ManualSTTDataMapper, {
      useClass: ManualSTTDataMapper
    });
    container.register<TariffDataMapper>(TariffDataMapper, {
      useClass: TariffDataMapper
    });
    container.register<BookingMapper>(BookingMapper, {
      useClass: BookingMapper
    });
    container.register<ShipmentBookingMapper>(ShipmentBookingMapper, {
      useClass: ShipmentBookingMapper
    });
    container.register<ListSttMapper>(ListSttMapper, {
      useClass: ListSttMapper
    });
    container.register<CnManifestMapper>(CnManifestMapper, {
      useClass: CnManifestMapper
    });
    container.register<PickupManifestMapper>(PickupManifestMapper, {
      useClass: PickupManifestMapper
    });
    container.register<VehicleDataMapper>(VehicleDataMapper, {
      useClass: VehicleDataMapper
    });
    container.register<StiSCMapper>(StiSCMapper, {
      useClass: StiSCMapper
    });
    container.register<STIMapper>(STIMapper, {
      useClass: STIMapper
    });
    container.register<BaggingMapper>(BaggingMapper, {
      useClass: BaggingMapper
    });
    container.register<StiDestMapper>(StiDestMapper, {
      useClass: StiDestMapper
    });
    container.register<StiDestScMapper>(StiDestScMapper, {
      useClass: StiDestScMapper
    });
    container.register<HandoverMapper>(HandoverMapper, {
      useClass: HandoverMapper
    });
    container.register<ShortlandMapper>(ShortlandMapper, {
      useClass: ShortlandMapper
    });
    container.register<PodDexMapper>(PodDexMapper, {
      useClass: PodDexMapper
    });
    container.register<DeliveryManifestMapper>(DeliveryManifestMapper, {
      useClass: DeliveryManifestMapper
    });
    container.register<PosMapper>(PosMapper, {
      useClass: PosMapper
    });
    container.register<ConsolidatorMapper>(ConsolidatorMapper, {
      useClass: ConsolidatorMapper
    });
    container.register<PartnerSubconsoleMapper>(PartnerSubconsoleMapper, {
      useClass: PartnerSubconsoleMapper
    });
    container.register<PartnerMapper>(PartnerMapper, {
      useClass: PartnerMapper
    });
    container.register<PosClientMapper>(PosClientMapper, {
      useClass: PosClientMapper
    });
    container.register<CustomProcessMapper>(CustomProcessMapper, {
      useClass: CustomProcessMapper
    });
    container.register<BulkBookingMapper>(BulkBookingMapper, {
      useClass: BulkBookingMapper
    });
    container.register<AdjustmentPenaltyMapper>(AdjustmentPenaltyMapper, {
      useClass: AdjustmentPenaltyMapper
    });
    container.register<RefundConfigurationMapper>(RefundConfigurationMapper, {
      useClass: RefundConfigurationMapper
    });
    container.register<BalanceMapper>(BalanceMapper, {
      useClass: BalanceMapper
    });
    container.register<CodIncomeMapper>(CodIncomeMapper, {
      useClass: CodIncomeMapper
    });
    container.register<TrackingMapper>(TrackingMapper, {
      useClass: TrackingMapper
    });
    container.register<CargoMapper>(CargoMapper, {
      useClass: CargoMapper
    });
    container.register<BeneficiaryMapper>(BeneficiaryMapper, {
      useClass: BeneficiaryMapper
    });
    container.register<ManualDeductAddSaldoMapper>(ManualDeductAddSaldoMapper, {
      useClass: ManualDeductAddSaldoMapper
    });
    container.register<BulkBookingCommissionMapper>(
      BulkBookingCommissionMapper,
      {
        useClass: BulkBookingCommissionMapper
      }
    );
    container.register<BookingCommissionMapper>(BookingCommissionMapper, {
      useClass: BookingCommissionMapper
    });
    container.register<TopUpVerificationMapper>(TopUpVerificationMapper, {
      useClass: TopUpVerificationMapper
    });
    container.register<TopupApprovalMapper>(TopupApprovalMapper, {
      useClass: TopupApprovalMapper
    });
    container.register<BulkDownloadMapper>(BulkDownloadMapper, {
      useClass: BulkDownloadMapper
    });
    container.register<DeliveryTieringPolicyMapper>(
      DeliveryTieringPolicyMapper,
      {
        useClass: DeliveryTieringPolicyMapper
      }
    );
    container.register<UpdateDeliveryMapper>(UpdateDeliveryMapper, {
      useClass: UpdateDeliveryMapper
    });
    container.register<MessagingServiceConfigurationMapper>(
      MessagingServiceConfigurationMapper,
      {
        useClass: MessagingServiceConfigurationMapper
      }
    );
    container.register<ReportMapper>(ReportMapper, {
      useClass: ReportMapper
    });
    container.register<IntegrationMonitoringMapper>(
      IntegrationMonitoringMapper,
      {
        useClass: IntegrationMonitoringMapper
      }
    );
    container.register<InstantBookingMapper>(InstantBookingMapper, {
      useClass: InstantBookingMapper
    });
    container.register<DashboardMapper>(DashboardMapper, {
      useClass: DashboardMapper
    });
    container.register<EmailMapper>(EmailMapper, {
      useClass: EmailMapper
    });
    container.register<BalanceTransactionMapper>(BalanceTransactionMapper, {
      useClass: BalanceTransactionMapper
    }),
      container.register<LastBalanceMapper>(LastBalanceMapper, {
        useClass: LastBalanceMapper
      });
    container.register<ForwardBookingCommissionMapper>(
      ForwardBookingCommissionMapper,
      {
        useClass: ForwardBookingCommissionMapper
      }
    );
    container.register<IncomingOutgoingMapper>(IncomingOutgoingMapper, {
      useClass: IncomingOutgoingMapper
    });
    container.register<ProgressiveCommissionConfigurationMapper>(
      ProgressiveCommissionConfigurationMapper,
      {
        useClass: ProgressiveCommissionConfigurationMapper
      }
    );

    container.register<ProgressiveCommissionDashboardMapper>(
      ProgressiveCommissionDashboardMapper,
      {
        useClass: ProgressiveCommissionDashboardMapper
      }
    );

    container.register<BaggingGroupingMapper>(BaggingGroupingMapper, {
      useClass: BaggingGroupingMapper
    });

    container.register<ClaimDataMapper>(ClaimDataMapper, {
      useClass: ClaimDataMapper
    });

    container.register<TrackDeliveryMapper>(TrackDeliveryMapper, {
      useClass: TrackDeliveryMapper
    });

    container.register<ServiceDelayMapper>(ServiceDelayMapper, {
      useClass: ServiceDelayMapper
    });

    container.register<ConfigPriceCodMapper>(ConfigPriceCodMapper, {
      useClass: ConfigPriceCodMapper
    });

    container.register<CourierManagementMapper>(CourierManagementMapper, {
      useClass: CourierManagementMapper
    });

    container.register<CodConfigurationMapper>(CodConfigurationMapper, {
      useClass: CodConfigurationMapper
    });

    container.register<HoldBalanceHistoryMapper>(HoldBalanceHistoryMapper, {
      useClass: HoldBalanceHistoryMapper
    });

    container.register<ConfigFakeDexIndicationMapper>(
      ConfigFakeDexIndicationMapper,
      {
        useClass: ConfigFakeDexIndicationMapper
      }
    );

    container.register<DexAssessmentMapper>(DexAssessmentMapper, {
      useClass: DexAssessmentMapper
    });

    // saldo & poin sender dashboard
    container.register<SaldoPoinMapper>(SaldoPoinMapper, {
      useClass: SaldoPoinMapper
    });

    // algo
    container.register<AuthAlgoMapper>(AuthAlgoMapper, {
      useClass: AuthAlgoMapper
    });
    container.register<PosFavoriteMapper>(PosFavoriteMapper, {
      useClass: PosFavoriteMapper
    });

    // ngen
    container.register<NGenMapper>(NGenMapper, {
      useClass: NGenMapper
    });

    // middleware
    container.register<MiddlewareMapper>(MiddlewareMapper, {
      useClass: MiddlewareMapper
    });

    // external
    container.register<UniversalPrintMapper>(UniversalPrintMapper, {
      useClass: UniversalPrintMapper
    });

    container.register<PaymentMapper>(PaymentMapper, {
      useClass: PaymentMapper
    });

    container.register<STTPaidUnpaidMapper>(STTPaidUnpaidMapper, {
      useClass: STTPaidUnpaidMapper
    });

    container.register<BalanceMinusPenaltyMapper>(BalanceMinusPenaltyMapper, {
      useClass: BalanceMinusPenaltyMapper
    });

    // universal
    container.register<UniversalMapper>(UniversalMapper, {
      useClass: UniversalMapper
    });

    container.register<CargoConfigurationMapper>(CargoConfigurationMapper, {
      useClass: CargoConfigurationMapper
    });

    container.register<UrgentDeliveryMapper>(UrgentDeliveryMapper, {
      useClass: UrgentDeliveryMapper
    });
    container.register<UrgentDeliveryManagementMapper>(
      UrgentDeliveryManagementMapper,
      {
        useClass: UrgentDeliveryManagementMapper
      }
    );

    container.register<InternationalDocumentMapper>(InternationalDocumentMapper, { useClass: InternationalDocumentMapper });
    container.register<CustomerMapper>(CustomerMapper, { useClass: CustomerMapper });
    container.register<ListPickupMapper>(ListPickupMapper, {
      useClass: ListPickupMapper
    });

    container.register<CollectibleCardMapper>(CollectibleCardMapper, {
      useClass: CollectibleCardMapper
    });
  }
}
